import type { AsComponentProps } from '@archipro-design/aria';
import type { SyntheticEvent } from 'react';
import type {
    AdEventHandlerOnClick,
    Tracker,
    TrackingEvent,
} from '@archipro-website/tracker';
import type { WebsiteTrackingProps } from '@modules/tracking/util/websiteTracking';
import { onAdClickVisitWebsite } from '@modules/tracking/util/websiteTracking';
import { Link } from '@remix-run/react';
import { keepArchiProDevAbsoluteUrlLocal } from '@modules/adverts/utils/utils';
import type { SupportedCountry } from '@archipro-website/localisation';

const isServer = typeof window === 'undefined';

const LEGACY_URLS = [
    'home-design-event',
    'building-guide',
    'box-the-architect-builder',
    'advertising',
    'careers',
    'contact-us',
];

interface HandledUrl {
    url: string;
    isExternal: boolean;
}

export const isExternalURL = (url?: string): boolean => {
    if (!url) {
        return false;
    }

    url = url.trim();

    if (!/^(http)|(https)|(mailto)|(tel)|(ftp):/i.test(url)) {
        return false;
    }

    const currentHost = !isServer && window?.location.hostname;
    const parsed = new URL(url);
    return parsed.hostname !== currentHost;
};

export const urlEventDiscovery = (e: React.MouseEvent): Element | null => {
    const target: Element = e.target as Element;
    if (!target) return null;

    if (target.tagName === 'A') {
        return target;
    }

    return target.closest('a');
};

export const goInNewTab = (path: string) => {
    if (isServer) {
        return;
    }
    window.open(path, '_blank');
};

export const goInCurrentTab = (path: string) => {
    if (isServer) {
        return;
    }
    window.location.href = path;
};

const isArchiproAbsoluteUrl = (url: string): boolean => {
    try {
        const parsedUrl = new URL(url);

        // Check if the hostname contains the word "archipro"
        return parsedUrl.hostname.includes('archipro');
    } catch (error) {
        // If parsing the URL fails, it's not a valid URL, so consider it as a relative path
        return false;
    }
};

const isRelativeUrl = (url: string): boolean => {
    try {
        const parsedUrl = new URL(url);

        // Check if it is a relative URL path
        return !!(
            !parsedUrl.hostname &&
            parsedUrl.pathname &&
            !parsedUrl.protocol
        );
    } catch (error) {
        // If parsing the URL fails, it's not a valid URL, so consider it as a relative path
        return true;
    }
};

interface AdvertLinkComponent extends AsComponentProps {
    onClick: (e: SyntheticEvent) => void;
    to: string;
    prefetch: 'intent';
}

interface AdvertHrefComponent extends AsComponentProps {
    onClick: (e: SyntheticEvent) => void;
    href: string;
    target?: string;
}

type GenericEventClick = (event?: TrackingEvent) => void;

export const createLinkedComponentParams = (
    tracker: Tracker,
    clickEvent: TrackingEvent | null,
    url: string,
    websiteTrackingProps: WebsiteTrackingProps,
    onClick?: GenericEventClick | null,
    onAdvertClick?: AdEventHandlerOnClick | null
): AdvertLinkComponent | AdvertHrefComponent => {
    const triggerClick = () => {
        if (clickEvent === null) {
            onClick && onClick();
        } else if (
            clickEvent === 'Ad_CTAClick' ||
            clickEvent === 'Ad_ImageClick' ||
            clickEvent === 'Ad_VideoClick'
        ) {
            onAdvertClick && onAdvertClick(clickEvent);
        } else {
            onClick && onClick(clickEvent);
        }
    };

    if (isRelativeUrl(url)) {
        // Use a remix Link
        return {
            onClick: () => {
                triggerClick();
            },
            as: Link,
            to: url,
            prefetch: 'intent',
        };
    }

    if (isArchiproAbsoluteUrl(url)) {
        // Use a redirect but assume within site. (e.g. not in remix)
        return {
            onClick: () => {
                triggerClick();
            },
            href: keepArchiProDevAbsoluteUrlLocal(url),
            as: 'a',
        };
    }

    // Assume is external link; Add url tracking and open in new tab
    return {
        onClick: (e) => {
            e.preventDefault();
            triggerClick();

            if (websiteTrackingProps) {
                onAdClickVisitWebsite(
                    {
                        websiteURL: url,
                        libraryItemID: websiteTrackingProps.libraryItemID,
                        professionalID: websiteTrackingProps.professionalID,
                        professionalTitle:
                            websiteTrackingProps.professionalTitle,
                        extraData: websiteTrackingProps.extraData,
                    },
                    tracker
                );
            } else {
                console.error(
                    'Please provide websiteTrackingProps for external links!'
                );
            }
        },
        href: url,
        as: 'a',
        target: '_blank',
    };
};

const normalizePath = (url: URL): string => {
    return url.pathname.replace(/^\/+/, '').toLowerCase();
};

export const handleLegacyUrl = (url: string, domain: string): HandledUrl => {
    try {
        const validUrl =
            url.startsWith('http://') || url.startsWith('https://')
                ? new URL(url)
                : new URL(url, domain);
        const cleanPath = normalizePath(validUrl);
        const isLegacyUrl = LEGACY_URLS.includes(cleanPath);
        return {
            url: isLegacyUrl ? validUrl.href : url,
            isExternal: validUrl.origin !== domain || isLegacyUrl,
        };
    } catch {
        return { url, isExternal: false };
    }
};

// moved here from web-platform
export const getDomain = (countryCode: SupportedCountry): string => {
    switch (countryCode) {
        case 'AU':
            return 'archipro.com.au';
        default:
            return 'archipro.co.nz';
    }
};

export const placeCountryDomain = (
    linkItem: {
        title: string;
        links: {
            title: string;
            to: string;
            isNonRemixRoute?: boolean;
        }[];
    },
    domain: string
) => {
    const { links } = linkItem;
    const linksWithCountry = links.map(({ title, to, isNonRemixRoute }) => ({
        title,
        to: to.replace('{AP_DOMAIN_PLACE}', domain),
        isNonRemixRoute,
    }));
    return {
        ...linkItem,
        links: linksWithCountry,
    };
};
